import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    CreateCompany: '/api/business/create',
    GetCompanyData: '/api/business/gets',
    GetData: '/api/business/get',

    //获取企业地图坐标列表
    GetMapLocation: '/api/business/getlocation',
    Createsecurityofficer: '/api/securityofficer/create',
    UpdataCompany: '/api/business/update',
    Getapisecurityofficer: "/api/business/get",
    GetOfficerList: "/api/securityofficer/gets",
    UpdataOfficer: "/api/securityofficer/update",
    DeleteOfficer: "/api/securityofficer/delete",
    Deletecompany: "/api/business/delete",
    UnDeleteCompany:"/api/business/undelete",
    GetSpecialoperatorList: "/api/specialoperator/gets",
    CreateSpecialoperatorList: "/api/specialoperator/create",
    UpdateSpecialoperatorList: "/api/specialoperator/update",
    DeleteSpecialoperatorList: "/api/specialoperator/delete",
    CreatePressurevessel: "/api/pressurevessel/create",
    GetPressurevesselList: "/api/pressurevessel/gets",
    UpdatePressurevessel: "/api/pressurevessel/update",
    DeletePressurevessel: "/api/pressurevessel/delete",
    GetgridheadList: "/api/gridhead/gets",
    GetUsersList: "/api/users/gets",
    Getsitude: "/api/clockin/gets", //获取打卡地点坐标  
    //证照情况
    CreateBusinessfile: "/api/businessfile/create",
    UpdateBusinessfile: "/api/businessfile/update",
    DeleteBusinessfile: "/api/businessfile/delete",
    GetBusinessfile: "/api/businessfile/get",
    GetBusinessfileList: "/api/businessfile/gets",
    //危化品使用情况
    CreateDangerproduct: "/api/dangerproduct/create",
    UpdatedDangerproduct: "/api/dangerproduct/update",
    DeleteDangerproduct: "/api/dangerproduct/delete",
    GetDangerproduct: "/api/dangerproduct/get",
    GetDangerproductList: "/api/dangerproduct/gets",


}

const businessApi = {
    //创建证照情况
    CreateBusinessfile(parameter) {
        return propertyService({
            url: apiUrl.CreateBusinessfile,
            method: 'post',
            params: parameter,
            needToken: true
        })
    },
    //修改证照情况
    UpdateBusinessfile(parameter) {
        return propertyService({
            url: apiUrl.UpdateBusinessfile,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除证照
    DeleteBusinessfile(parameter) {
        return propertyService({
            url: apiUrl.DeleteBusinessfile,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    // 获取证照情况
    GetBusinessfile(parameter) {
        return propertyService({
            url: apiUrl.GetBusinessfile,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    // 获取证照列表
    GetBusinessfileList(parameter) {
        return propertyService({
            url: apiUrl.GetBusinessfileList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //创建危化品使用情况
    CreateDangerproduct(parameter) {
        return propertyService({
            url: apiUrl.CreateDangerproduct,
            method: 'post',
            params: parameter,
            needToken: true
        })
    },
    //修改危化品使用情况

    UpdatedDangerproduct(parameter) {
        return propertyService({
            url: apiUrl.UpdatedDangerproduct,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除危化品使用情况
    DeleteDangerproduct(parameter) {
        return propertyService({
            url: apiUrl.DeleteDangerproduct,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    // 获取危化品使用情况
    GetDangerproduct(parameter) {
        return propertyService({
            url: apiUrl.GetDangerproduct,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    // 获取危化品使用情况列表
    GetDangerproductList(parameter) {
        return propertyService({
            url: apiUrl.GetDangerproductList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },

    //保存企业信息
    CreateCompany(parameter) {
        return propertyService({
            url: apiUrl.CreateCompany,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //获取企业列表
    GetCompanyData(parameter) {
        return propertyService({
            url: apiUrl.GetCompanyData,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    // 获取企业地图坐标列表
    GetMapLocation(parameter) {
        return propertyService({
            url: apiUrl.GetMapLocation,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },

    // 获取企业信息
    GetData(parameter) {
        return propertyService({
            url: apiUrl.GetData,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //创建安全检查人员
    Createsecurityofficer(parameter) {
        return propertyService({
            url: apiUrl.Createsecurityofficer,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //修改企业信息
    UpdataCompany(parameter) {
        return propertyService({
            url: apiUrl.UpdataCompany,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    // 获取安全员信息
    Getapisecurityofficer(parameter) {
        return propertyService({
            url: apiUrl.Getapisecurityofficer,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    // 获取安全员列表
    GetOfficerList(parameter) {
        return propertyService({
            url: apiUrl.GetOfficerList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //修改安全员信息
    UpdataOfficer(parameter) {
        return propertyService({
            url: apiUrl.UpdataOfficer,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除安全员信息 
    DeleteOfficer(parameter) {
        return propertyService({
            url: apiUrl.DeleteOfficer,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    //删除公司信息
    DeleteCompany(parameter) {
        return propertyService({
            url: apiUrl.Deletecompany,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    //恢复公司信息
    UnDeleteCompany(parameter) {
        return propertyService({
            url: apiUrl.UnDeleteCompany,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //获取特种作业人员列表
    GetSpecialoperatorList(parameter) {
        return propertyService({
            url: apiUrl.GetSpecialoperatorList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //修改特种作业人员列表
    UpdateSpecialoperatorList(parameter) {
        return propertyService({
            url: apiUrl.UpdateSpecialoperatorList,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除特种作业人员
    DeleteSpecialoperatorList(parameter) {
        return propertyService({
            url: apiUrl.DeleteSpecialoperatorList,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    //创建特种作业人员
    CreateSpecialoperatorList(parameter) {
        return propertyService({
            url: apiUrl.CreateSpecialoperatorList,
            method: 'post',
            data: parameter,
            needToken: true
        })

    },
    //创建容压器
    CreatePressurevessel(parameter) {
        return propertyService({
            url: apiUrl.CreatePressurevessel,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //修改容压器
    UpdatePressurevessel(parameter) {
        return propertyService({
            url: apiUrl.UpdatePressurevessel,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除容压器
    DeletePressurevessel(parameter) {
        return propertyService({
            url: apiUrl.DeletePressurevessel,
            method: 'DELETE',
            params: parameter,
            needToken: true
        })
    },
    //获取容压器列表
    GetPressurevesselList(parameter) {
        return propertyService({
            url: apiUrl.GetPressurevesselList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //获取网格长列表
    GetgridheadList(parameter) {
        return propertyService({
            url: apiUrl.GetgridheadList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //获取网格长列表
    GetUsersList(parameter) {
        return propertyService({
            url: apiUrl.GetUsersList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //获取企业列表
    Getsitude(parameter) {
        return propertyService({
            url: apiUrl.Getsitude,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },

}
export {
    businessApi
}